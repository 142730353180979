import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { StoreConsumer } from "../../store"

import { ReactComponent as SvgChevronDown } from "../../images/icons/chevron-down.svg"
import { ReactComponent as SvgEllipsis } from "../../images/icons/ellipsis.svg"

const Heading = styled.h3.attrs({ className: `is-vhidden` })``

const Toggle = styled.button.attrs({ type: `button` })`
  width: 100%;
  padding: 1em 1.3em;
  position: relative;
  border-top: 1px solid;

  &::before {
    content: "";
    width: 0.5em;
    height: 0.5em;
    position: absolute;
    top: 1.6em;
    left: 0;
    background-color: ${(props) => props.theme.colorGreyDark};
    border-radius: 50%;

    ${({ active }) =>
      active &&
      css`
        background-color: ${(props) => props.theme.colorRed};
      `}
  }

  svg {
    width: 0.8em;
    height: 0.5em;
    position: absolute;
    top: 1.8em;
    right: 0;

    ${({ active }) =>
      active &&
      css`
        transform: rotate(180deg);
      `}
  }
`

const Text = styled.div.attrs({ className: `styled styled--large-type` })`
  ${({ expanded }) =>
    !expanded &&
    css`
      max-height: 70vh;
      overflow: hidden;
      mask: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 1) calc(100% - 2em),
        rgba(0, 0, 0, 0) 100%
      );
    `}
`

const More = styled.button.attrs({ type: `button` })`
  width: 100%;
  display: block;
  position: relative;
  padding: 1em 0;
  padding-right: 1.3em;
  border-top: 1px solid;

  &:hover {
    opacity: 0.6;
  }

  ${({ expanded }) =>
    expanded &&
    css`
      margin-top: 2em;
    `}

  svg {
    width: 0.9em;
    height: 0.5em;
    position: absolute;
    top: 1.5em;
    right: 0;

    ${({ expanded }) =>
      expanded &&
      css`
        transform: rotate(180deg);
      `}
  }
`

const Container = styled.article`
  ${({ active }) =>
    !active &&
    css`
      ${Text},
      ${More} {
        display: none;
      }
    `}
`

const Item = ({
  data: { title, titleLevel, text },
  indexInList,
  active,
  activateItem,
  expanded,
  onExpandClick,
}) => {
  const toggleClick = () => activateItem(indexInList)

  return (
    <Container active={active}>
      <Heading as={titleLevel} dangerouslySetInnerHTML={{ __html: title }} />

      <Toggle active={active} onClick={toggleClick}>
        <span
          dangerouslySetInnerHTML={{ __html: title }}
          className="styled-p-leading"
        />

        <SvgChevronDown aria-hidden="true" />
      </Toggle>

      <Text expanded={expanded} dangerouslySetInnerHTML={{ __html: text }} />

      <StoreConsumer>
        {({ translations }) => (
          <More expanded={expanded} onClick={onExpandClick}>
            <span className="styled-p">
              {translations[expanded ? `collapse` : `read_more`]}
            </span>

            <SvgEllipsis aria-hidden="true" />
          </More>
        )}
      </StoreConsumer>
    </Container>
  )
}

Item.propTypes = {
  data: PropTypes.object.isRequired,
  indexInList: PropTypes.number.isRequired,
  active: PropTypes.bool.isRequired,
  activateItem: PropTypes.func.isRequired,
  expanded: PropTypes.bool.isRequired,
  onExpandClick: PropTypes.func.isRequired,
}

export default Item
