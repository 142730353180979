import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import Grid from "../dotgrid/grid"
import Row from "../dotgrid/row"
import Cell from "../dotgrid/cell"
import Item from "./item"

const Container = styled.section``

const Title = styled.h2``

const Accordion = ({
  data: {
    acf: { title, titleLevel, items },
  },
}) => {
  const [activeItem, setActiveItem] = useState(-1)
  const [itemExpanded, setItemExpanded] = useState(false)
  const itemWrapsRef = useRef([])
  const clickedRef = useRef(false)

  const scrollToActiveItemWrap = () => {
    if (!clickedRef.current) return

    const el = itemWrapsRef.current[activeItem]
    if (el) el.scrollIntoView(true)
  }

  useEffect(() => {
    itemWrapsRef.current = itemWrapsRef.current.slice(
      0,
      items ? items.length : 0
    )
  }, [items])

  useEffect(() => {
    scrollToActiveItemWrap()
    setItemExpanded(false)
  }, [activeItem])

  useEffect(() => {
    if (!itemExpanded) scrollToActiveItemWrap()
  }, [itemExpanded])

  if (!items || !items.length) return null

  const activateItem = (index) => {
    clickedRef.current = true
    setActiveItem(index != activeItem ? index : -1)
  }

  const itemExpandClick = () => {
    clickedRef.current = true
    setItemExpanded((expanded) => !expanded)
  }

  return (
    <Container>
      <Grid>
        <Row>
          <Cell size={8} mq={{ mediumDown: { size: 23, pullY: 1 } }}>
            <Title
              as={titleLevel}
              className="styled-h3 styled"
              dangerouslySetInnerHTML={{
                __html: title,
              }}
            />
          </Cell>

          <Cell size={10} pushX={4} mq={{ mediumDown: { size: 23, pushX: 0 } }}>
            {items.map((item, i) => (
              <div key={i} ref={(n) => (itemWrapsRef.current[i] = n)}>
                <Item
                  data={item}
                  active={i == activeItem}
                  expanded={itemExpanded}
                  onExpandClick={itemExpandClick}
                  indexInList={i}
                  activateItem={activateItem}
                />
              </div>
            ))}
          </Cell>
        </Row>
      </Grid>
    </Container>
  )
}

Accordion.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Accordion
