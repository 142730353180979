import React, { useEffect, useState, useRef } from "react"
import { TransitionGroup, CSSTransition } from "react-transition-group"
import PropTypes from "prop-types"
import styled from "styled-components"
import { rem } from "polished"

import Grid from "../dotgrid/grid"
import Row from "../dotgrid/row"
import Cell from "../dotgrid/cell"
import Quote from "./quote"

const timeout = 200

const Container = styled.section`
  background-color: ${(props) => props.theme.colorGreyDark};
  position: relative;

  .rtg-exit {
  }

  .rtg-exit-active {
    opacity: 0;
    transition: opacity ${timeout}ms linear;
  }

  .rtg-enter {
    width: 100%;
    position: absolute;
    transform: translateY(1em);
    opacity: 0;
  }

  .rtg-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity ${timeout}ms ${timeout}ms linear,
      transform ${timeout}ms ${timeout}ms linear;
  }
`

const Nav = styled.nav`
  &.\\--mobile {
    display: none;
  }

  @media ${(props) => props.theme.mediumDown} {
    &.\\--mobile {
      display: block;
    }

    &.\\--desktop {
      display: none;
    }
  }

  button {
    opacity: 0.6;
    padding: 0.2em 0.4em;

    &:not(:last-child) {
      margin-right: 0.2em;
    }

    &:hover {
      color: ${(props) => props.theme.colorRed};
      opacity: 1;
    }

    &.\\--active {
      opacity: 1;
    }
  }
`

let quoteMinHeight = 0

const Testimonials = ({
  data: {
    acf: { title, quotes },
  },
}) => {
  const [active, setActive] = useState(0)
  const quoteWrapRef = useRef()

  const quote = quotes[active]

  const setMinHeight = () => {
    quoteWrapRef.current.style.minHeight = `auto`
    const height = Math.max(
      quoteWrapRef.current.getBoundingClientRect().height,
      quoteMinHeight
    )
    quoteWrapRef.current.style.minHeight = rem(height)
    quoteMinHeight = height
  }

  const navBtnClick = (e) => {
    e.preventDefault()
    setActive(parseInt(e.currentTarget.dataset.index))
  }

  const winResize = () => {
    quoteMinHeight = 0
    setMinHeight()
  }

  useEffect(() => {
    if (typeof window !== `undefined`) {
      setMinHeight()
      window.addEventListener(`resize`, winResize, { passive: true })
    }

    return () => {
      if (typeof window !== `undefined`) {
        window.removeEventListener(`resize`, winResize, { passive: true })
      }
    }
  })

  useEffect(() => {
    setMinHeight()
  }, [active])

  const nav = (className) => (
    <Nav className={className}>
      {quotes.map((item, i) => (
        <button
          key={i}
          type="button"
          className={`styled-h4 ${i === active && `--active`}`}
          title={[item.firstname, item.lastname].filter(Boolean).join(` `)}
          onClick={navBtnClick}
          data-index={i}
        >
          {i + 1}
        </button>
      ))}
    </Nav>
  )

  if (!quotes || !quotes.length) return null

  return (
    <Container>
      <Grid>
        <Row pullY={3} mq={{ xsmallDown: { pullY: 8 } }}>
          <Cell size={10} mq={{ mediumDown: { size: 23 } }}>
            <h2 className="styled-h4">{title}</h2>

            {nav(`--desktop`)}
          </Cell>

          <Cell
            size={11}
            pushX={2}
            mq={{ mediumDown: { size: 23, pushX: 0, pushY: 1 } }}
          >
            <div ref={quoteWrapRef} style={{ position: `relative` }}>
              <TransitionGroup>
                <CSSTransition
                  key={active}
                  timeout={{ exit: timeout, enter: timeout * 2 }}
                  classNames="rtg"
                >
                  <Quote data={quote} />
                </CSSTransition>
              </TransitionGroup>
            </div>
          </Cell>
        </Row>

        <Row>
          <Cell size={23}>{nav(`--mobile`)}</Cell>
        </Row>
      </Grid>
    </Container>
  )
}

Testimonials.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Testimonials
