import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { rem } from "polished"
import Img from "gatsby-image/withIEPolyfill"

import Link from "../link"
import { StoreConsumer } from "../../store"

const Container = styled.section`
  position: relative;
  background-color: ${(props) => props.theme.colorGreyLight};
  min-height: ${rem(600)};

  @media ${(props) => props.theme.xxxlargeDown} {
    min-height: ${rem(450)};
  }

  @media ${(props) => props.theme.largeDown} {
    min-height: unset;
  }

  ${(props) =>
    props.colorTheme === `dark` &&
    css`
      background-color: ${(props) => props.theme.colorGreyDark};
    `}

  ${(props) =>
    props.colorTheme === `backgroundImage` &&
    css`
      color: ${(props) => props.theme.colorWhite};
    `}
`

const Bg = styled.div.attrs({ ariaHidden: true })`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
  }

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
`

const TextContainer = styled.div`
  display: flex;
  max-width: ${rem(1680)};
  margin: 0 auto;
  z-index: 2;
  position: relative;
  padding: ${rem(70)} ${rem(50)} ${rem(120)} ${rem(50)};
  color: ${(props) => props.color || `inherit`};

  @media ${(props) => props.theme.mediumDown} {
    flex-direction: column;
    padding: ${rem(70)} ${rem(50)};
  }

  @media ${(props) => props.theme.xsmallDown} {
    padding: ${rem(15)};
  }
`

const TitleRows = styled.div`
  width: 60%;

  @media ${(props) => props.theme.mediumDown} {
    width: 100%;
  }
`

const TitleRow = styled.h3`
  color: ${(props) => props.color || `inherit`};

  &:not(:first-child) {
    margin-top: ${rem(20)};

    @media ${(props) => props.theme.mediumDown} {
      margin-top: ${rem(5)};
    }
  }
`

const TitleNumber = styled.span`
  font-size: ${rem(90)};

  html[lang="jp"] & {
    font-size: ${rem(66)};
  }

  @media ${(props) => props.theme.xxxlargeDown} {
    font-size: ${rem(80)};

    html[lang="jp"] & {
      font-size: ${rem(50)};
    }
  }

  @media ${(props) => props.theme.largeDown} {
    font-size: ${rem(56)};

    html[lang="jp"] & {
      font-size: ${rem(40)};
    }
  }
`

const TitleDescription = styled.span`
  font-size: ${rem(60)};

  html[lang="jp"] & {
    font-size: ${rem(40)};
  }

  @media ${(props) => props.theme.xxxlargeDown} {
    font-size: ${rem(40)};

    html[lang="jp"] & {
      font-size: ${rem(35)};
    }
  }

  @media ${(props) => props.theme.largeDown} {
    font-size: ${rem(35)};

    html[lang="jp"] & {
      font-size: ${rem(28)};
    }
  }
`

const Text = styled.div`
  width: 40%;
  max-width: ${rem(470)};
  font-size: ${rem(24)};
  padding-left: ${rem(20)};
  margin-top: ${rem(30)};

  @media ${(props) => props.theme.mediumDown} {
    width: 100%;
    font-size: ${rem(18)};
    padding-left: 0;
  }
`

const LinkElement = styled.p`
  margin-top: ${rem(30)};

  @media ${(props) => props.theme.xsmallDown} {
    margin-bottom: ${rem(15)};
  }
`

const TextBlock = ({
  colorTheme,
  data: {
    acf: {
      title,
      text,
      linkUrl,
      linkText,
      backgroundImage,
      titleRows,
      textColor,
    },
  },
}) => {
  const hasBgImg = backgroundImage && backgroundImage.localFile

  if (!colorTheme && hasBgImg) {
    colorTheme = `backgroundImage`
  }

  return (
    <Container colorTheme={colorTheme}>
      <TextContainer color={textColor}>
        {titleRows && (
          <TitleRows>
            {titleRows.map((titleRow, i) => (
              <TitleRow
                color={titleRow.text_color}
                className="styled-h4"
                key={`title-row-${i}`}
              >
                <TitleNumber>
                  {titleRow.number}
                  {` `}
                </TitleNumber>

                <TitleDescription>{titleRow.description}</TitleDescription>
              </TitleRow>
            ))}
          </TitleRows>
        )}

        <Text>
          <p dangerouslySetInnerHTML={{ __html: text }} />

          {linkUrl && linkUrl.length && (
            <LinkElement>
              <StoreConsumer>
                {({ translations }) => (
                  <Link to={linkUrl} className="styled-a">
                    {linkText || translations[`read_more`]}
                  </Link>
                )}
              </StoreConsumer>
            </LinkElement>
          )}
        </Text>
      </TextContainer>

      {backgroundImage && backgroundImage.localFile && (
        <Bg>
          <Img
            fluid={backgroundImage.localFile.childImageSharp.fluid}
            objectPosition="center top"
            objectFit="cover"
            style={{
              width: `100%`,
              height: `100%`,
              display: `block`,
            }}
            alt={title}
          />
        </Bg>
      )}
    </Container>
  )
}

TextBlock.propTypes = {
  colorTheme: PropTypes.string,
  data: PropTypes.object.isRequired,
}

export default TextBlock
