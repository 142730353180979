import React from "react"
import PropTypes from "prop-types"

import Img from "gatsby-image/withIEPolyfill"
import styled from "styled-components"

import Grid from "../dotgrid/grid"
import Row from "../dotgrid/row"
import Cell from "../dotgrid/cell"

const Container = styled.section``

const Logo = styled.figure`
  width: 100%;
  height: 0;
  padding-bottom: 33%;
  position: relative;

  .gatsby-image-wrapper {
    width: 70%;
    height: 60%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

const CustomersSelected = ({ title, data: { edges } }) => {
  if (!edges || !edges.length) return null

  edges = edges.filter(
    ({ node }) =>
      node.acf.customerLogotype && node.acf.customerLogotype.localFile
  )

  if (!edges.length) return null

  return (
    <Container>
      <Grid>
        {title && (
          <Row>
            <Cell
              size={10}
              mq={{ mediumDown: { size: 14 }, xsmallDown: { size: 23 } }}
            >
              <h2 className="styled-h4">{title}</h2>
            </Cell>
          </Row>
        )}

        <Row pushY={5} pullY={1} mq={{ xsmallDown: { pushY: 8 } }}>
          {edges.map(({ node }, i) => (
            <Cell
              key={i}
              size={5}
              pushX={i % 4 !== 0 && 1}
              pushY={i >= 4 && 1}
              mq={{
                largeDown: {
                  size: 7,
                  pushX: i % 3 !== 0 ? 1 : 0,
                  pushY: i >= 3 ? 1 : 0,
                },
                smallDown: {
                  size: 11,
                  pushX: i % 2 !== 0 ? 1 : 0,
                  pushY: i >= 2 ? 1 : 0,
                },
              }}
            >
              <Logo>
                <Img
                  objectFit="contain"
                  fluid={
                    node.acf.customerLogotype.localFile.childImageSharp.fluid
                  }
                  title={node.acf.customerName}
                  alt={node.acf.customerName}
                  style={{
                    position: `absolute`,
                  }}
                />
              </Logo>
            </Cell>
          ))}
        </Row>
      </Grid>
    </Container>
  )
}

CustomersSelected.propTypes = {
  title: PropTypes.string,
  data: PropTypes.object.isRequired,
}

export default CustomersSelected
