import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import MetaWp from "../components/meta-wp"
import Hero from "../components/hero"
import UspsWithDescription from "../components/usps-with-description"
import CustomersSelected from "../components/customers-selected"
import Platform from "../components/platform"
import Testimonials from "../components/testimonials"
import VideoCarousel from "../components/video-carousel"
import TextBlock from "../components/text-block-alt"
import Blurbs from "../components/blurbs"
import Accordion from "../components/accordion"

const LandingPage = ({ data, pageContext }) => {
  const {
    metaDefaults,
    metaWpGlobal,
    metaWpPage,
    hideFooterSections,
    hero,
    uspsWithDescription,
    platform,
    customersSelected,
    testimonials,
    video,
    textBlock,
    blurbs,
    accordion,
    wp,
    siteMessage,
  } = data
  const { customersTitle } = wp.acf
  const { translations, menus } = pageContext

  return (
    <Layout
      translations={translations}
      menus={menus}
      metaDefaults={metaDefaults}
      metaWpGlobal={metaWpGlobal}
      hideFooterSections={hideFooterSections.acf}
      siteMessage={siteMessage}
    >
      <MetaWp page={metaWpPage} global={metaWpGlobal} defaults={metaDefaults} />

      <Hero data={hero} />

      <UspsWithDescription data={uspsWithDescription} />

      <Platform data={platform} />

      <TextBlock data={textBlock} />

      <CustomersSelected data={customersSelected} title={customersTitle} />

      <Testimonials data={testimonials} />

      <VideoCarousel data={video} />

      <Blurbs data={blurbs} />

      <Accordion data={accordion} />
    </Layout>
  )
}

LandingPage.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
}

export default LandingPage

export const pageQuery = graphql`
  query ($id: String!) {
    ...MetaDefaultsFragment
    ...MetaWpGlobalFragment
    ...MetaWpPageFragment
    ...SiteMessageFragment
    ...HideFooterSectionsPageFragment

    ...HeroPageFragment
    ...UspsWithDescriptionPageFragment
    ...PlatformPageFragment
    ...CustomersSelectedFragment
    ...TestimonialsPageFragment
    ...VideoPageFragment
    ...TextBlockPageFragment
    ...BlurbsPageFragment
    ...AccordionPageFragment

    wp: wordpressPage(id: { eq: $id }) {
      acf {
        customersTitle: logos_title
      }
    }
  }
`
