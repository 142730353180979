import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import Grid from "../dotgrid/grid"
import Row from "../dotgrid/row"
import Cell from "../dotgrid/cell"
import Spacer from "../dotgrid/spacer"

const dots = { color: `#000`, opacity: 0.2 }

const Container = styled.section`
  background-color: ${(props) => props.theme.colorGreyDark};
`

const Content = styled.article`
  padding: 3.5% 0;

  @media ${(props) => props.theme.mediumDown} {
    padding: 6% 0;
  }

  h2 {
    color: ${(props) => props.theme.colorWhite};
    margin-top: -0.15em;
  }

  .-description {
    @media ${(props) => props.theme.mediumDown} {
      display: none;
    }
  }
`

const UspsWithDescription = ({
  data: {
    acf: { usps },
  },
}) => {
  if (!usps || !usps.length) return null

  return (
    <Container>
      <Grid noBottomPadding>
        <Spacer dots={dots} size={1} />
      </Grid>

      {usps.map(({ title, text }, i) => (
        <React.Fragment key={i}>
          <Content>
            <Grid noTopPadding noBottomPadding>
              <Row>
                <Cell
                  maxSize={15}
                  pushX={i % 2 === 0 && 3}
                  mq={{ mediumDown: { maxSize: 23, pushX: 0 } }}
                  style={{ order: i % 2 === 0 && 1 }}
                >
                  <h2 className="styled-h1">{title}</h2>
                </Cell>

                <Cell
                  size={5}
                  pushX={i % 2 !== 0 && 3}
                  className="-description"
                >
                  <p className="styled-p">{text}</p>
                </Cell>
              </Row>
            </Grid>
          </Content>

          {i !== usps.length - 1 && (
            <Grid noTopPadding noBottomPadding>
              <Spacer dots={dots} size={2} mq={{ xsmallDown: { size: 1 } }} />
            </Grid>
          )}
        </React.Fragment>
      ))}

      <Grid noTopPadding>
        <Spacer dots={dots} size={2} />
      </Grid>
    </Container>
  )
}

UspsWithDescription.propTypes = {
  data: PropTypes.object.isRequired,
}

export default UspsWithDescription
