import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import Grid from "../dotgrid/grid"
import Row from "../dotgrid/row"
import Cell from "../dotgrid/cell"
import Link from "../link"

const Container = styled.section`
  color: ${(props) => props.theme.colorWhite};
  background-color: ${(props) => props.theme.colorPurple};

  p:first-child {
    opacity: 0.6;
  }
`

const Figures = styled.div`
  display: flex;
  align-items: flex-start;

  div {
    width: 30%;
    max-width: 400px;
    position: relative;

    &::before {
      content: "";
      float: left;
      width: 1px;
      margin-left: -1px;
      padding-top: 100%;
    }

    &::after {
      content: "";
      display: table;
      clear: both;
    }
  }

  .-rectangle {
    transform: translateX(-50%);
    border: 5px solid ${(props) => props.theme.colorRed};
  }

  .-circle {
    background-color: ${(props) => props.theme.colorWhite};
    border-radius: 50%;
    margin-right: -3.5%;
    margin-left: auto;
  }

  .-triangle {
    color: ${(props) => props.theme.colorGreyDark};

    div {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: currentColor;
      clip-path: polygon(0% 100%, 100% 100%, 50% 0%);
    }
  }
`

const Platform = ({
  data: {
    acf: { title, text, linkText, linkUrl },
  },
}) => {
  return (
    <Container>
      <Grid>
        <Row pullY={5} mq={{ xsmallDown: { pullY: 10 } }}>
          <Cell size={11} mq={{ xsmallDown: { size: 23 } }}>
            <h2 className="styled-h4">{title}</h2>
          </Cell>

          <Cell
            size={11}
            pushX={1}
            mq={{ xsmallDown: { size: 23, pushX: 0 } }}
            className="styled -paragraphs"
          >
            <p
              className="styled-p-leading"
              dangerouslySetInnerHTML={{ __html: text }}
            />

            {linkText && linkUrl && (
              <p className="styled-p-leading">
                <Link to={linkUrl}>{linkText}</Link>
              </p>
            )}
          </Cell>
        </Row>

        <Figures>
          <div className="-rectangle" />

          <div className="-circle" />

          <div className="-triangle">
            <div />
          </div>
        </Figures>
      </Grid>
    </Container>
  )
}

Platform.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Platform
